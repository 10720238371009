import React from "react"
import { graphql, Link } from "gatsby"
import Masonry from "react-masonry-css"
import Img from "gatsby-image"

//components
import Layout from "../components/Layout"
import Section from "../components/Section"
import LastNews from "../components/LastNews"

const IndexFr = ({ data }) => {
  const langSelect = [
    { locale: "fr", path: "" },
    { locale: "nl", path: "" },
  ]
  const langCurrent = "fr"
  const langPath = "commercants"
  let i = 0

  function shuffle(arr) {
    var i, j, temp
    for (i = arr.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1))
      temp = arr[i]
      arr[i] = arr[j]
      arr[j] = temp
    }
    return arr
  }
  let shuffleData = shuffle(data.com.nodes)

  const myColumn = {
    default: 4,
    1200: 4,
    980: 3,
    740: 2,
    500: 1,
  }
  return (
    <Layout langSelect={langSelect} langCurrent={langCurrent}>
      <Section className="news">
        <LastNews langCurrent={langCurrent} />
      </Section>
      <Section className="main">
        <Masonry
          breakpointCols={myColumn}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {shuffleData.map((item, index) => {
            i++
            if (i <= 9) {
              return (
                <Link
                  to={`/${langCurrent}/commercants/${item.slug}`}
                  key={index}
                >
                  <Img fluid={item.logo.fluid} alt={item.title} />
                  <h2>{item.title}</h2>

                  <p>{item.subtitle}</p>
                </Link>
              )
            } else {
              return false
            }
          })}
        </Masonry>
      </Section>
      <Section className="more">
        <Link to={`/${langCurrent}/${langPath}`}>
          voir tous les commerçants
        </Link>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query GetSomeCommercant {
    com: allContentfulCommercant(filter: { node_locale: { eq: "fr" } }) {
      nodes {
        title
        node_locale
        slug
        subtitle
        logo {
          fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
    }
  }
`
export default IndexFr
