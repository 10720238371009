import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const getData = graphql`
  {
    newsFr: allContentfulNews(
      filter: { dateFin: { lt: "now" }, node_locale: { eq: "fr" } }
    ) {
      nodes {
        title
        slug
        image {
          fixed(width: 450, height: 200) {
            ...GatsbyContentfulFixed_tracedSVG
          }
        }
      }
    }
    newsNl: allContentfulNews(
      filter: { dateFin: { lt: "now" }, node_locale: { eq: "nl" } }
    ) {
      nodes {
        title
        slug
        image {
          fixed(width: 450, height: 200) {
            ...GatsbyContentfulFixed_tracedSVG
          }
        }
      }
    }
  }
`

const LastNews = ({ langCurrent }) => {
  const data = useStaticQuery(getData)
  const mydata = langCurrent === "fr" ? data.newsFr : data.newsNl
  const result = mydata.nodes[0]
  const langPath = langCurrent === "fr" ? "actus" : "news"

  if (mydata.nodes.length > 0) {
    return (
      <div className="lastNews">
        <Img fixed={result.image.fixed} alt={result.title} />
        <Link to={`/${langCurrent}/${langPath}/${result.slug}`}>
          {result.title}
        </Link>
      </div>
    )
  } else {
    return null
  }
}

export default LastNews
